<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <!-- <this-header /> -->
    <b-overlay
      :show="showOver"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <p id="cancel-label">กรุณารอสักครู่...</p>
        </div>
      </template>
      <div class="anan-set-nav-conttainer2 mt-150">
        <router-link :to="{ name: 'payment-create' }">
          <button class="btn btn-gradient-primary mb-1 mr-1">
            {{ $t("backToPaymentPage") }}
          </button>
        </router-link>
        <validation-observer ref="simpleRules">
          <button
            class="btn btn-gradient-primary mb-1"
            :disabled="isDisabled"
            @click="Submit()"
          >
            {{ $t("saveData") }}
          </button>
          <b-row>
            <b-col lg="6">
              <div class="card">
                <div class="header-cardx p-2">
                  <h3 class="mb-0">
                    {{ $t("recipientAddress") }}
                  </h3>
                </div>

                <div class="p-2">
                  <b-row>
                    <b-col md="10">
                      <b-form-group
                        label-for="h-getfullname"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t("key-77") }} :
                          <span class="text-danger">*</span>
                        </template>
                        <validation-provider
                          #default="{ errors }"
                          name="fullname"
                          rules="required"
                        >
                          <b-form-input
                            id="h-getfullname"
                            v-model="getfullname"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="10">
                      <b-form-group
                        label-for="h-address-fix"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t("address") }} :
                          <span class="text-danger">*</span>
                        </template>
                        <validation-provider
                          #default="{ errors }"
                          name="address"
                          rules="required"
                        >
                          <b-form-input
                            id="h-address-fix"
                            v-model="addressget"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="10">
                      <b-form-group
                        label-for="h-province-name"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                        :label="$t('province')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Province"
                          rules="required"
                        >
                          <v-select
                            id="h-province-name"
                            v-model="selectedProvince"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :state="errors.length > 0 ? false : null"
                            label="province_name"
                            :reduce="(ele) => ele.province_id"
                            :options="provinceOptions"
                            class="w-100 mr-1"
                            @input="updateDistricts"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="10">
                      <b-form-group
                        label-for="h-district"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                        :label="$t('key-102')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Province"
                          rules="required"
                        >
                          <v-select
                            id="h-district"
                            v-model="selectedDistrict"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :state="errors.length > 0 ? false : null"
                            label="district_name"
                            :reduce="(ele) => ele.district_id"
                            :options="filteredDistricts"
                            class="w-100 mr-1"
                            @input="updateSubDistricts"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="10">
                      <b-form-group
                        label-for="h-subdistrict"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                        :label="$t('key-103')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Province"
                          rules="required"
                        >
                          <v-select
                            id="h-subdistrict"
                            v-model="selectedSubDistrict"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :state="errors.length > 0 ? false : null"
                            label="sub_district_name"
                            :reduce="(ele) => ele.tumbon_id"
                            :options="filteredSubDistricts"
                            class="w-100 mr-1"
                            @input="updatePostalcode"
                          />
                          <!--  -->
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="10">
                      <b-form-group
                        label-for="h-parcel-code"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t("postalCode") }} :
                          <span class="text-danger">*</span>
                        </template>
                        <validation-provider
                          #default="{ errors }"
                          name="parcel code"
                          rules="required"
                        >
                          <b-form-input
                            id="h-parcel-code"
                            v-model="postalCode"
                            :state="errors.length > 0 ? false : null"
                            label="zipcode"
                            :disabled="postalCode"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="10">
                      <b-form-group
                        label-for="h-parcel-code"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t("key-81") }} :
                          <span class="text-danger">*</span>
                        </template>
                        <validation-provider
                          #default="{ errors }"
                          name="parcel code"
                          rules="required"
                        >
                          <b-form-input
                            id="h-parcel-code"
                            v-model="telnumber"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="10">
                      <b-form-group
                        label-for="h-checkProduct"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <button
                          class="btn btn-primary btn-sm"
                          @click="$bvModal.show('modal-receive')"
                        >
                          {{ $t("chooseRecipientAddress") }}
                        </button>
                      </b-form-group>
                    </b-col>

                    <b-col md="10">
                      <b-form-group
                        label-for="h-customerCode"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t("deliveryType") }}
                        </template>

                        <div>
                          <b-form-radio
                            v-model="transport"
                            name="some-radio9"
                            :value="1"
                            class="custom-control-primary mb-1"
                            @change="transportsnew(transport)"
                          >
                            {{ $t("pickupTimingNote") }}
                          </b-form-radio>

                          <b-form-radio
                            v-model="transport"
                            name="some-radio9"
                            :value="2"
                            class="custom-control-primary mb-1"
                            @change="transportsnew(transport)"
                          >
                            {{ $t("deliveryCompanyPrice") }}
                          </b-form-radio>

                          <b-form-radio
                            v-model="transport"
                            name="some-radio9"
                            :value="3"
                            class="custom-control-primary"
                            @change="transportsnew(transport)"
                          >
                            {{ $t("privateCarrierNotice") }}
                          </b-form-radio>

                          <div v-if="transport === 3" class="ml-2 mt-50">
                            <b-form-radio
                              v-model="transports"
                              name="some-radio"
                              :value="4"
                              class="custom-control-primary mb-50"
                              @change="transportsnew(transports)"
                            >
                              {{ $t("shippingFeeOrigin") }}
                            </b-form-radio>

                            <b-form-radio
                              v-model="transports"
                              name="some-radio"
                              :value="5"
                              class="custom-control-primary"
                              @change="transportsnew(transports)"
                            >
                              {{ $t("payDestinationShipping") }}
                            </b-form-radio>

                            <validation-provider
                              #default="{ errors }"
                              name="product type"
                              rules="required"
                            >
                              <v-select
                                id="some-radio9"
                                v-model="productType"
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                :state="errors.length > 0 ? false : null"
                                label="name"
                                :options="checktransports"
                                class="w-50 mt-50"
                                @input="inputtransports()"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </div>
                      </b-form-group>

                      <hr class="my-2 border-dotted" />
                    </b-col>

                    <b-col md="10">
                      <b-form-group
                        label-for="h-delivery"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t("senderAddress") }}
                        </template>

                        <validation-provider
                          #default="{ errors }"
                          name="product type"
                          rules="required"
                        >
                          <v-select
                            id="h-delivery"
                            v-model="delivery"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :state="errors.length > 0 ? false : null"
                            label="label"
                            :reduce="(item) => item.value"
                            :options="optiondelivery"
                            class="w-100 mr-1"
                            @input="checkdelivery(delivery)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col v-if="delivery === 3" md="10">
                      <b-form-group
                        label-for="h-fullname"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t("senderName") }}
                        </template>
                        <div>
                          <small class="text-danger">
                            {{ $t("key-104") + `${general} ฿` }}
                          </small>
                          <validation-provider
                            #default="{ errors }"
                            name="full name"
                            rules="required"
                          >
                            <b-form-input
                              id="h-fullname"
                              v-model="deliveryname"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </b-form-group>
                    </b-col>

                    <b-col v-if="delivery === 3" md="10">
                      <b-form-group
                        label-for="h-address"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t("address") }}
                        </template>
                        <b-form-textarea
                          id="h-address"
                          v-model="deliveryaddress"
                          type="text"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col v-if="delivery === 3" md="10">
                      <b-form-group
                        label-for="h-keyword"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t("senderNumberContact") }}
                        </template>

                        <b-form-input
                          id="h-keyword"
                          v-model="deliverykeyword"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="10">
                      <b-form-group
                        label-for="h-customerCode"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t("wantReceipt") }}?
                        </template>

                        <div class="mt-25">
                          <b-form-checkbox
                            v-model="receipt"
                            name="some-radio9"
                            class="custom-control-primary"
                            @input="checkreceipt(receipt)"
                          >
                            {{ $t("wantReceipt") }}
                          </b-form-checkbox>

                          <button
                            v-if="receipt === true"
                            class="btn-select"
                            @click="$bvModal.show('modal-receive-amend')"
                          >
                            <span v-if="itemsTax">
                              <span class="text-right">
                                <!-- v-for="(arritem,index) in itemsTax"
                              :key="index" -->
                                {{ $t("name") }} {{ itemsTax.texfullname }}
                                <br />
                                {{ $t("type") }}
                                {{ checktypetex(itemsTax.typetex) }}<br />
                                {{ $t("TaxID") }} {{ itemsTax.texnumber }}<br />
                                {{ $t("address") }} {{ itemsTax.address }}<br />
                                {{ $t("key-81") }} {{ itemsTax.telephone
                                }}<br />
                                <img
                                  v-if="itemsTax.filetex"
                                  :src="GetImg('filetex', itemsTax.filetex)"
                                  accept=".jpg, .png, .jpeg"
                                  alt="รูปภาพ"
                                  class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                                />
                              </span>
                            </span>
                            <span v-else>
                              {{ $t("taxpayerInfo") }}
                            </span>
                          </button>
                        </div>
                      </b-form-group>
                    </b-col>

                    <b-col md="10">
                      <b-form-group
                        label-for="h-checkProduct"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t("coupon") }}
                        </template>

                        <div v-if="couponitem" class="btn btn-outline-primary">
                          <span>{{ $t("code") }} {{ couponitem.name }} </span>
                          <feather-icon
                            class="text-danger cursor-pointer"
                            icon="TrashIcon"
                            @click="Deletecoupon(couponitem)"
                          />
                          <!-- <span>โค้ด {{ couponitem.name }}</span><br>
                        <span>รายละเอียด {{ couponitem.description }}</span><br>
                        <span>ส่วนลด {{ couponitem.discount }}</span> -->
                        </div>
                        <div v-else>
                          <button
                            class="btn btn-outline-primary"
                            @click="$bvModal.show('modal-coupon')"
                          >
                            {{ $t("selectCoupon") }}
                          </button>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>

            <b-modal
              id="modal-receive"
              :title="$t('chooseRecipientAddress')"
              size="lg"
              @ok="addAddress()"
            >
              <button
                class="btn btn-outline-primary btn-sm"
                @click="EditType()"
              >
                <i class="fal fa-plus" /> {{ $t("addNewAddress") }}
              </button>
              <div class="mt-1">
                <b-table
                  responsive="sm"
                  :items="getaddress"
                  :fields="addrFields"
                  show-empty
                >
                  <template #cell(set)="data">
                    <b-form-checkbox
                      v-model="data.item.isSelected"
                      :value="true"
                    />
                  </template>

                  <template #cell(actions)="data">
                    <feather-icon
                      icon="Edit3Icon"
                      class="mr-50 text-primary cursor-pointer"
                      @click="EditType(data.item)"
                    />

                    <feather-icon
                      icon="TrashIcon"
                      class="text-danger cursor-pointer"
                      @click="Deleteaddress(data.item)"
                    />
                  </template>
                </b-table>
              </div>
            </b-modal>
            <b-modal
              id="modal-receive-add"
              :title="$t('addAddress')"
              @ok="addNewAddress()"
            >
              <validation-observer ref="addNewAddress">
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      label-for="h-fullname"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        {{ $t("key-77") }}
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="full name"
                        rules="required"
                      >
                        <b-form-input
                          id="h-fullname"
                          v-model="fullname"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-phone"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        {{ $t("key-81") }}
                      </template>

                      <validation-provider
                        #default="{ errors }"
                        name="phone number"
                        rules="required"
                      >
                        <b-form-input
                          id="h-phone"
                          v-model="phoneNum"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-address"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        {{ $t("address") }}
                      </template>

                      <validation-provider
                        #default="{ errors }"
                        name="address"
                        rules="required"
                      >
                        <b-form-textarea
                          id="h-address"
                          v-model="address"
                          :state="errors.length > 0 ? false : null"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-province"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        {{ $t("province") }}
                      </template>

                      <validation-provider
                        #default="{ errors }"
                        name="product type"
                        rules="required"
                      >
                        <v-select
                          id="h-province"
                          v-model="Province"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :state="errors.length > 0 ? false : null"
                          label="province_name"
                          :reduce="(ele) => ele.province_id"
                          :options="provinceOptionsnew"
                          class="w-100 mr-1"
                          @input="updateDistrictsnew"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-district"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        {{ $t("key-102") }}
                      </template>

                      <validation-provider
                        #default="{ errors }"
                        name="district"
                        rules="required"
                      >
                        <v-select
                          id="h-district"
                          v-model="district"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :state="errors.length > 0 ? false : null"
                          label="district_name"
                          :reduce="(ele) => ele.district_id"
                          :options="filteredDistrictsnew"
                          class="w-100 mr-1"
                          @input="updateSubDistrictsnew"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-subdistrict"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        {{ $t("key-103") }}
                      </template>

                      <validation-provider
                        #default="{ errors }"
                        name="subdistrict"
                        rules="required"
                      >
                        <v-select
                          id="h-subdistrict"
                          v-model="subdistrict"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :state="errors.length > 0 ? false : null"
                          label="sub_district_name"
                          :reduce="(ele) => ele.tumbon_id"
                          :options="filteredSubDistrictsnew"
                          class="w-100 mr-1"
                          @input="updatePostalcodenew"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-parcel-code"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        {{ $t("postalCode") }}
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="parcel code"
                        rules="required"
                      >
                        <b-form-input
                          id="h-parcel-code"
                          v-model="postal"
                          :state="errors.length > 0 ? false : null"
                          label="zipcode"
                          :disabled="postal"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-set-default"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        {{ $t("setAddress") }}
                      </template>

                      <b-form-checkbox
                        v-model="setDefault"
                        name="some-radio9"
                        :value="1"
                        class="custom-control-primary"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </b-modal>

            <b-modal
              id="modal-receive-amend"
              :title="$t('taxpayerInfo')"
              size="lg"
              @ok="additemtex()"
            >
              <button class="btn btn-outline-primary btn-sm" @click="EditTex()">
                <i class="fal fa-plus" /> {{ $t("addInfo") }}
              </button>
              <div class="mt-1">
                <b-table
                  responsive="sm"
                  :items="itemsTaxpayer"
                  :fields="Fieldstex"
                  show-empty
                >
                  <template #cell(filetex)="data">
                    <img
                      v-if="data.item.filetex"
                      :src="GetImg('filetex', data.item.filetex)"
                      accept=".jpg, .png, .jpeg"
                      alt="รูปภาพ"
                      class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                      @click="showPicture(data.item.filetex)"
                    />
                    <vue-image-lightbox-carousel
                      ref="lightbox"
                      :show="showLightbox"
                      :images="images"
                      :show-caption="false"
                      @close="closeBox()"
                    />
                  </template>
                  <template #cell(set)="data">
                    <b-form-checkbox
                      v-model="itemsTaxall"
                      :value="data.item"
                      :unchecked-value="false"
                    />
                  </template>
                  <template #cell(typetex)="data">
                    {{ checktypetex(data.item.typetex) }}
                  </template>
                  <template #cell(actions)="data">
                    <feather-icon
                      icon="Edit3Icon"
                      class="mr-50 text-primary cursor-pointer"
                      @click="EditTex(data.item)"
                    />

                    <feather-icon
                      icon="TrashIcon"
                      class="text-danger cursor-pointer"
                      @click="Deletetex(data.item)"
                    />
                  </template>
                  <!--

                 -->
                </b-table>
              </div>
            </b-modal>

            <!-- Modal coupon -->
            <b-modal
              id="modal-coupon"
              :title="$t('selectCoupon')"
              size="lg"
              centered
              @ok="submitcoupon()"
            >
              <validation-observer ref="coupon">
                <b-table
                  responsive="sm"
                  :items="itemcoupon"
                  :fields="Fieldscoupon"
                  show-empty
                >
                  <template #cell(setcoupons)="data">
                    <b-form-radio
                      v-model="couponcheck"
                      :value="data.item"
                      :unchecked-value="false"
                    />
                  </template>
                  <template #cell(coupons)="data">
                    <div
                      style="background-color: rgb(97, 201, 149); padding: 20px"
                    >
                      <span /><br />
                      <span>{{ $t("code") }} {{ data.item.name }}</span
                      ><br />
                      <span /><br />
                    </div>
                  </template>
                  <template #cell(detailcoupons)="data">
                    <!-- style="background-color: blue;" -->
                    <div
                      style="
                        background-color: rgb(142, 243, 206);
                        padding: 20px;
                      "
                    >
                      <span>{{ $t("code") }} {{ data.item.name }}</span
                      ><br />
                      <span
                        >{{ $t("details") }} {{ data.item.description }}</span
                      ><br />
                      <span>{{ $t("discount") }} {{ data.item.discount }}</span>
                    </div>
                  </template>
                </b-table></validation-observer
              >
            </b-modal>
            <b-modal
              id="modal-receive-add-amend"
              :title="$t('addTaxUser')"
              @ok.prevent="addNewTex()"
            >
              <validation-observer ref="addNewtexse">
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      label-for="h-typetex"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        <small class="text-danger">*</small> {{ $t("type") }}
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="full name"
                        rules="required"
                      >
                        <div class="demo-inline-spacing mt-n1">
                          <b-form-radio
                            v-model="typetex"
                            name="some-radios"
                            :value="1"
                          >
                            {{ $t("Individual") }}
                          </b-form-radio>
                          <b-form-radio
                            v-model="typetex"
                            name="some-radios"
                            :value="2"
                          >
                            {{ $t("legalEntity") }}
                          </b-form-radio>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label-for="h-texfullname"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        <small class="text-danger">*</small> {{ $t("name") }}
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="full name"
                        rules="required"
                      >
                        <b-form-input
                          id="h-texfullname"
                          v-model="texfullname"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{
                          errors[0] ? $t("requiredField") : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-addresstex"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        <small class="text-danger">*</small> {{ $t("address") }}
                      </template>

                      <validation-provider
                        #default="{ errors }"
                        name="addresstex"
                        rules="required"
                      >
                        <b-form-textarea
                          id="h-addresstex"
                          v-model="addresstex"
                          :state="errors.length > 0 ? false : null"
                          type="text"
                        />
                        <small class="text-danger">{{
                          errors[0] ? $t("requiredField") : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-telephone"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        <small class="text-danger">*</small> {{ $t("key-81") }}
                      </template>

                      <validation-provider
                        #default="{ errors }"
                        name="phone number"
                        rules="required"
                      >
                        <b-form-input
                          id="h-telephone"
                          v-model="telephone"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{
                          errors[0] ? $t("requiredField") : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-parcel-code"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        <small class="text-danger">*</small> {{ $t("TaxID") }}
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Taxpayer"
                        rules="required"
                      >
                        <b-form-input
                          id="h-parcel-code"
                          v-model="Taxpayer"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{
                          errors[0] ? $t("requiredField") : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-parcel-code"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        <small class="text-danger">*</small>
                        {{ $t("taxDocument") }}
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Taxpayer"
                        rules="required"
                      >
                        <div>
                          <b-form-file
                            v-model="selectfile"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            multiple
                            @change="filetex"
                          />
                          <img
                            v-if="namefile"
                            :src="GetImg('filetex', namefile)"
                            accept=".jpg, .png, .jpeg"
                            alt="รูปภาพ"
                            class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                            @click="showPicture(namefile)"
                          />
                          <vue-image-lightbox-carousel
                            ref="lightbox"
                            :show="showLightbox"
                            :images="images"
                            :show-caption="false"
                            @close="closeBox()"
                          />
                        </div>
                        <small class="text-danger">{{
                          errors[0] ? $t("requiredField") : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </b-modal>
            <b-modal
              id="modal-history"
              title="ที่อยู่ที่เคยส่งเร็วๆนี้"
              size="lg"
              hide-footer
            >
              <div class="mt-1">
                <b-table
                  responsive="sm"
                  :items="gethistory"
                  :fields="addrFieldshistory"
                  show-empty
                >
                  <template #cell(set)="data">
                    <p
                      class="text-primary"
                      role="button"
                      @click="ActiveData(data.item)"
                    >
                      {{ $t("active") }}
                    </p>
                    <!-- <b-form-checkbox
                      v-model="data.item.isSelected"
                      :value="true"
                    /> -->
                  </template>
                  <template #cell(fullname)="data">
                    {{
                      data.item.address.fullname
                        ? data.item.address.fullname
                        : "-"
                    }}
                  </template>
                  <template #cell(address)="data">
                    {{
                      data.item.address.address
                        ? data.item.address.address
                        : "-"
                    }}
                  </template>
                  <template #cell(tel)="data">
                    {{ data.item.address.tel ? data.item.address.tel : "-" }}
                  </template>
                  <template #cell(sender)="data">
                    {{
                      data.item.delivery.name ? data.item.delivery.name : "-"
                    }}
                  </template>
                  <template #cell(deliveryType)="data">
                    {{
                      data.item.typedelivery.pro_name
                        ? data.item.typedelivery.pro_name
                        : "-"
                    }}
                  </template>
                  <template #cell(province)="data">
                    {{
                      data.item.address.province_name
                        ? data.item.address.province_name
                        : "-"
                    }}
                  </template>

                  <template #cell(actions)="data">
                    <feather-icon
                      icon="TrashIcon"
                      class="text-danger cursor-pointer"
                      @click="Deletehistory(data.item)"
                    />
                  </template>
                </b-table>
              </div>
            </b-modal>

            <b-col md="6">
              <div class="card">
                <div class="header-cardx p-2">
                  <h3 class="mb-0">
                    {{ $t("termsAndConditions") }}
                  </h3>
                </div>
                <!-- <div class="p-2">
                <b-form-checkbox
                  v-model="payNow"
                  name="some-radio9"
                  class="custom-control-primary"
                  @input="checkreceipt(payNow)"
                >
                  <h5>จ่ายทันที</h5>

                </b-form-checkbox>
              </div> -->
                <div class="p-2">
                  <b-col md="12">
                    <div class="w-50">
                      <p class="mb-0 font-weight-bolder text-left mr-2">
                        {{ $t("receiveInstructions") }}
                      </p>
                    </div>
                  </b-col>
                  <b-col md="12">
                    <div>
                      <p class="mb-0 font-weight-bolder text-left mr-2">
                        {{ $t("receiveInstructions1") }}
                      </p>
                    </div>
                  </b-col>
                  <b-col md="12">
                    <div>
                      <p class="mb-0 font-weight-bolder text-left mr-2">
                        {{ $t("receiveInstructions2") }}
                      </p>
                    </div>
                  </b-col>
                  <b-col md="12">
                    <div>
                      <p class="mb-0 font-weight-bolder text-left mr-2">
                        {{ $t("receiveInstructions3") }}
                      </p>
                    </div>
                  </b-col>
                  <b-col md="12">
                    <div>
                      <p class="mb-0 font-weight-bolder text-left mr-2">
                        {{ $t("receiveInstructions4") }}
                      </p>
                    </div>
                  </b-col>
                  <b-col md="12">
                    <div>
                      <p class="mb-0 font-weight-bolder text-left mr-2">
                        {{ $t("receiveInstructions5") }}
                      </p>
                    </div>
                  </b-col>
                  <b-col md="12" class="mt-2 mb-2">
                    <b-form-checkbox
                      v-model="payNow"
                      name="some-radio9"
                      :value="1"
                      class="custom-control-primary"
                    >
                      {{ Arritem[0].username }} {{ $t("termsAgreement") }}
                    </b-form-checkbox>
                  </b-col>

                  <!-- <div class="d-flex align-items-center mb-1">
                  <b-button
                    variant="danger"
                    class="mt-1 mr-50 col-md-12"
                  >
                    ยกเลิกรายการ
                  </b-button>
                </div> -->
                </div>
              </div>

              <div class="card">
                <div class="header-cardx p-2">
                  <h3 class="mb-0">
                    {{ $t("amountDue") }}
                  </h3>
                </div>
                <!-- <div class="p-2">
                <b-form-checkbox
                  v-model="payNow"
                  name="some-radio9"
                  class="custom-control-primary"
                  @input="checkreceipt(payNow)"
                >
                  <h5>จ่ายทันที</h5>

                </b-form-checkbox>
              </div> -->
                <div class="p-2">
                  <div class="d-flex align-items-center mb-1">
                    <div class="w-50">
                      <p class="mb-0 font-weight-bolder text-right mr-2">
                        {{ $t("customerBalance") }}
                      </p>
                    </div>

                    <div class="w-50" style="color: rgb(1, 155, 1)">
                      {{ Commas((sumuser = paymentuser.balance)) }} ฿
                    </div>
                  </div>

                  <div class="d-flex align-items-center mb-1">
                    <div class="w-50">
                      <p class="mb-0 font-weight-bolder text-right mr-2">
                        {{ $t("importFee") }}
                      </p>
                    </div>

                    <div class="w-50">{{ Commas(sumitem) }} ฿</div>
                  </div>

                  <div class="d-flex align-items-center mb-1">
                    <div class="w-50">
                      <p class="mb-0 font-weight-bolder text-right mr-2">
                        {{ $t("deliveryBy") }}
                      </p>
                    </div>

                    <div class="w-50">
                      {{ bydelivery ? bydelivery : "-" }}
                    </div>
                  </div>

                  <div class="d-flex align-items-center mb-1">
                    <div class="w-50">
                      <p class="mb-0 font-weight-bolder text-right mr-2">
                        {{ $t("serviceFee") }}
                      </p>
                    </div>

                    <div class="w-50">
                      {{ deliverypay ? Commas(deliverypay) : 0 }} ฿
                    </div>
                  </div>

                  <div class="d-flex align-items-center mb-1">
                    <div class="w-50">
                      <p class="mb-0 font-weight-bolder text-right mr-2">
                        {{ $t("shippingFee") }}
                      </p>
                    </div>

                    <div class="w-50">
                      {{ sumshipping ? Commas(sumshipping) : 0 }} ฿
                    </div>
                  </div>

                  <div class="d-flex align-items-center mb-1">
                    <div class="w-50">
                      <p class="mb-0 font-weight-bolder text-right mr-2">
                        {{ $t("paymentType") }}
                      </p>
                    </div>

                    <div class="w-50">
                      {{ $t("deductBalance") }}
                    </div>
                  </div>

                  <div class="d-flex align-items-center mb-1">
                    <div class="w-50">
                      <p class="mb-0 font-weight-bolder text-right mr-2">
                        {{ $t("totalAmount") }}
                      </p>
                    </div>

                    <div class="w-50">
                      {{
                        Commas((sumpay = sumitem + deliverypay + sumshipping))
                      }}
                      ฿
                    </div>
                  </div>

                  <div class="p-2">
                    <label for="textarea-default" class="font-weight-bolder">{{
                      $t("remarks")
                    }}</label>
                    <b-form-textarea
                      id="textarea-default"
                      v-model="remark"
                      rows="3"
                    />
                  </div>

                  <!-- <div class="d-flex align-items-center mb-1">
                  <b-button
                    variant="danger"
                    class="mt-1 mr-50 col-md-12"
                  >
                    ยกเลิกรายการ
                  </b-button>
                </div> -->
                </div>
              </div>

              <!-- <div class="card">
              <div class="header-cardx p-2">
                <h3 class="mb-0">
                  ยอดที่ต้องชำระ
                </h3>
              </div>

              <div class="p-2">
                <b-row>
                  <b-col md="12">
                    <b-form-checkbox
                      v-model="payNow"
                      name="some-radio9"
                      :value="true"
                      class="custom-control-primary"
                    >
                      จ่ายทันที
                    </b-form-checkbox>
                  </b-col>

                </b-row>
              </div>
            </div> -->
            </b-col>
          </b-row>
        </validation-observer>
        <b-row>
          <b-col md="12">
            <div class="card">
              <div class="header-cardx p-2">
                <div class="d-flex align-items-center justify-content-between">
                  <h4 class="font-weight-bolder">
                    {{ $t("parcelCodeList") }}
                  </h4>
                </div>
              </div>
              <div class="p-2 table-container">
                <table class="styled-table mt-n25">
                  <thead>
                    <tr>
                      <th>
                        {{ $t("lotSequence") }}
                      </th>
                      <th>
                        {{ $t("parcelCode") }}
                      </th>
                      <!-- <th>
                        {{ $t('orderList') }}
                      </th> -->
                      <th>
                        {{ $t("mnemonic") }}
                      </th>
                      <th>
                        {{ $t("memberCode") }}
                      </th>
                      <th>
                        {{ $t("quantity") }}
                      </th>
                      <th>
                        {{ $t("weight") }}
                      </th>
                      <th>
                        {{ $t("widthCm") }}
                      </th>
                      <th>
                        {{ $t("heightCm") }}
                      </th>
                      <th>
                        {{ $t("lengthCm") }}
                      </th>
                      <th>
                        {{ $t("cubic") }}
                      </th>
                      <th>
                        {{ $t("type") }}
                      </th>
                      <th>
                        {{ $t("serviceFee") }}
                      </th>
                      <th>
                        {{ $t("pricePerKilo") }}
                      </th>
                      <th>
                        {{ $t("pricePerCubic") }}
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr style="background: #fff; color: black">
                      <th
                        class="text-right"
                        colspan="4"
                        style="background: #fff; color: black"
                      >
                        <b class="mr-2">{{ $t("total") }}</b>
                      </th>
                      <th
                        class="text-center"
                        scope="col"
                        style="background: #fff; color: black"
                      >
                        {{
                          Commas(
                            Arritem.reduce(
                              (total, item) => total + item.product_amount,
                              0
                            )
                          )
                        }}
                      </th>
                      <th
                        class="text-center"
                        scope="col"
                        style="background: #fff; color: black"
                      >
                        {{
                          Commas(
                            Arritem.reduce(
                              (total, item) =>
                                total + item.weight * item.product_amount,
                              0
                            )
                          )
                        }}
                      </th>
                      <th
                        class="text-center"
                        scope="col"
                        style="background: #fff; color: black"
                      >
                        {{
                          Commas(
                            Arritem.reduce(
                              (total, item) => total + item.width,
                              0
                            )
                          )
                        }}
                      </th>
                      <th
                        class="text-center"
                        scope="col"
                        style="background: #fff; color: black"
                      >
                        {{
                          Commas(
                            Arritem.reduce(
                              (total, item) => total + item.height,
                              0
                            )
                          )
                        }}
                      </th>
                      <th scope="col" style="background: #fff; color: black">
                        {{
                          Commas(
                            Arritem.reduce(
                              (total, item) => total + item.long,
                              0
                            )
                          )
                        }}
                      </th>
                      <th scope="col" style="background: #fff; color: black">
                        {{
                          CommasCue(
                            Arritem.reduce(
                              (total, item) =>
                                total + item.charge_cue * item.product_amount,
                              0
                            )
                          )
                        }}
                      </th>
                      <th scope="col" style="background: #fff; color: black">
                        <b />
                      </th>
                      <th scope="col" style="background: #fff; color: black">
                        {{
                          Commas(
                            Arritem.reduce(
                              (total, item) =>
                                total +
                                (item.other +
                                  item.shipping_fee +
                                  item.product_inspection_fee +
                                  item.re_wrap_fee +
                                  item.packaging_fee),
                              0
                            )
                          )
                        }}
                      </th>
                      <th scope="col" style="background: #fff; color: black">
                        {{
                          Commas(
                            Arritem.reduce(
                              (total, item) =>
                                total +
                                (item.charge_per_kilo === item.charge_per_save
                                  ? item.charge_per_kilo
                                  : 0),
                              0
                            )
                          )
                        }}
                      </th>
                      <th scope="col" style="background: #fff">
                        {{
                          Commas(
                            Arritem.reduce(
                              (total, item) =>
                                total +
                                (item.charge_per_cue === item.charge_per_save
                                  ? item.charge_per_cue
                                  : 0),
                              0
                            )
                          )
                        }}
                      </th>
                      <!-- <th
                    class="text-center"
                    style="background: #fff; color: black;"
                  >
                    <b />
                  </th>
                  <th
                    class="text-center"
                    style="background: #fff; color: black;"
                  >
                    <b />
                  </th>
                  <th
                    class="text-center"
                    style="background: #fff; color: black;"
                  >
                    <b />
                  </th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in Arritem"
                      :key="index"
                      :class="`ctx-${item.status}`"
                    >
                      <!-- {{ items }} -->
                      <td scope="col" class="text-center" style="width: 150px">
                        <i
                          v-if="item.transportType === 1"
                          class="fas fa-truck-moving"
                        />
                        <i
                          v-if="item.transportType === 2"
                          class="fas fa-ship"
                        />
                        {{ item.lot_name }}/{{ item.lot_order }}
                        <!-- <b-form-checkbox
                      v-if="item.out_thaiwarehouse"
                      v-model="item.select"
                      @input="checkboxselect(item.select,item)"
                    /> -->
                        <!-- {{ selectItem[index] }} -->
                      </td>
                      <td scope="col">
                        {{ item.postnumber }}
                        <!-- <feather-icon
                      icon="Edit3Icon"
                      class="mr-50 text-primary cursor-pointer"
                      @click="EditType(item)"
                    /> -->
                      </td>
                      <!-- <td scope="col">
                        {{ item.tag_id ? item.tag_id : '-' }} -->
                      <!-- <i
                      class="fas fa-truck-moving"
                    />
                    <i
                      class="fas fa-ship"
                    /> -->
                      <!-- <router-link :to="{name : 'admin-warehouse-lot'}">
                      {{ item.lot_name ? item.lot_name: '' }}
                    </router-link>{{ item.lot_order ? `/${item.lot_order}`: '' }} -->
                      <!-- </td> -->
                      <td scope="col" class="text-left">
                        <div
                          class="text-truncate"
                          style="max-width: 400px !important"
                        >
                          {{ item.keyword ? item.keyword : "-" }}
                        </div>
                        <!-- <router-link :to="{name : 'admin-tracking-edit', params: { id: item._id }}">
                      {{ item.postnumber ? item.postnumber: '-' }}
                    </router-link> -->
                      </td>
                      <td scope="col" class="text-left">
                        {{ item.user_member ? item.user_member : "" }}
                        <!-- <router-link :to="{name : 'admin-tracking-edit'}"> -->
                        <!-- {{ item.tag_id ? item.tag_id: '-' }} -->
                        <!-- </router-link> -->
                      </td>
                      <td scope="col">
                        {{ Commas(item.product_amount) }}
                        <!-- {{ getoption.username }} -->
                      </td>
                      <td scope="col">
                        {{ Commas(item.weight * item.product_amount) }}
                      </td>
                      <td scope="col">
                        {{ Commas(item.width) }}
                      </td>
                      <td scope="col">
                        {{ Commas(item.height) }}
                      </td>
                      <td scope="col">
                        {{ Commas(item.long) }}
                      </td>
                      <td scope="col">
                        {{ CommasCue(item.charge_cue * item.product_amount) }}
                      </td>
                      <td scope="col" style="width: 50px">
                        {{
                          item.product_type
                            ? item.product_type.pro_initial
                            : "-"
                        }}
                      </td>
                      <td scope="col">
                        {{
                          Commas(
                            item.other +
                              item.shipping_fee +
                              item.product_inspection_fee +
                              item.re_wrap_fee +
                              item.packaging_fee
                          )
                        }}
                      </td>
                      <td>
                        <span
                          v-if="
                            item.discount &&
                            item.charge_per_kilo === item.charge_per_save
                          "
                        >
                          {{ Commas(item.charge_per_kilo)
                          }}<span class="text-danger"
                            >-{{ Commas(item.discount) }}% =
                            {{ Commas(item.amount_discount) }}
                          </span>
                        </span>
                        <span v-else>{{
                          item.charge_per_kilo === item.charge_per_save
                            ? Commas(item.charge_per_save)
                            : "-"
                        }}</span>
                      </td>
                      <td>
                        <span
                          v-if="
                            item.discount &&
                            item.charge_per_cue === item.charge_per_save
                          "
                        >
                          {{ Commas(item.charge_per_cue)
                          }}<span class="text-danger"
                            >-{{ Commas(item.discount) }}% =
                            {{ Commas(item.amount_discount) }}
                          </span>
                        </span>
                        <span v-else>{{
                          item.charge_per_cue === item.charge_per_save
                            ? Commas(item.charge_per_save)
                            : "-"
                        }}</span>
                      </td>
                      <!-- <td scope="col">
                      {{ item.charge_per_kilo === item.charge_per_save ? item.charge_per_save: '-' }}
                    </td>
                    <td scope="col">
                      {{ item.charge_per_cue === item.charge_per_save ? item.charge_per_save: '-' }}
                    </td> -->
                    </tr>
                  </tbody>
                  <tfoot class="text-center">
                    <tr>
                      <th
                        class="text-right"
                        colspan="12"
                        style="background: #fff; color: black"
                      >
                        {{ $t("total") }}
                      </th>
                      <th
                        class="text-right"
                        colspan="3"
                        style="background: #fff; color: black"
                      >
                        <b v-if="couponitem" class="mr-2">
                          <span
                            style="text-decoration: line-through; color: red"
                            >{{
                              Commas(
                                (sumpayment = Arritem.reduce(
                                  (total, item) =>
                                    total +
                                    (item.charge_per_save ===
                                      item.charge_per_cue && item.discount
                                      ? item.amount_discount
                                      : 0) +
                                    (item.charge_per_save ===
                                      item.charge_per_kilo && item.discount
                                      ? item.amount_discount
                                      : 0) +
                                    (!item.discount
                                      ? item.charge_per_save
                                      : 0) +
                                    item.other +
                                    item.shipping_fee +
                                    item.product_inspection_fee +
                                    item.re_wrap_fee +
                                    item.packaging_fee,
                                  0
                                ))
                              )
                            }}
                            ฿</span
                          >
                          {{
                            Commas(
                              Arritem.reduce(
                                (total, item) =>
                                  total +
                                  (item.charge_per_save ===
                                    item.charge_per_cue && item.discount
                                    ? item.amount_discount
                                    : 0) +
                                  (item.charge_per_save ===
                                    item.charge_per_kilo && item.discount
                                    ? item.amount_discount
                                    : 0) +
                                  (!item.discount ? item.charge_per_save : 0) +
                                  item.other +
                                  item.shipping_fee +
                                  item.product_inspection_fee +
                                  item.re_wrap_fee +
                                  item.packaging_fee,
                                0
                              ) - couponitem.discount
                            )
                          }}
                          ฿
                        </b>
                        <b v-else class="mr-2">
                          {{
                            Commas(
                              (sumitem = Arritem.reduce(
                                (total, item) =>
                                  total +
                                  (item.charge_per_save ===
                                    item.charge_per_cue && item.discount
                                    ? item.amount_discount
                                    : 0) +
                                  (item.charge_per_save ===
                                    item.charge_per_kilo && item.discount
                                    ? item.amount_discount
                                    : 0) +
                                  (!item.discount ? item.charge_per_save : 0) +
                                  item.other +
                                  item.shipping_fee +
                                  item.product_inspection_fee +
                                  item.re_wrap_fee +
                                  item.packaging_fee,
                                0
                              ))
                            )
                          }}
                          ฿
                          <!-- {{ Commas(sumitem = Arritems.reduce((total,item) => total + item.charge_per_save +item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee ,0)) }} ฿ -->
                        </b>
                        <!-- <b
                        v-if="couponitem"
                        class="mr-2"
                      >
                        <span style="text-decoration: line-through; color: red;">{{ Commas(Arritem.reduce((total,item)=> total + item.charge_per_save +(item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee),0) ) }} ฿</span>
                        {{ Commas(sumitem = Arritem.reduce((total,item)=> total + item.charge_per_save +(item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee),0) - couponitem.discount ) }} ฿
                      </b>
                      <b
                        v-else
                        class="mr-2"
                      >
                        {{ Commas(sumitem = Arritem.reduce((total,item)=> total + item.charge_per_save +(item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee),0) ) }} ฿
                      </b> -->
                      </th>
                    </tr>
                    <tr>
                      <th
                        class="text-right"
                        colspan="12"
                        style="background: #fff; color: black"
                      >
                        <b>{{ $t("discountCoupon") }}</b>
                      </th>
                      <th
                        class="text-right"
                        colspan="3"
                        style="background: #fff; color: black"
                      >
                        <b v-if="couponitem" style="color: red" class="mr-2">
                          {{ Commas(couponitem.discount) }} ฿
                        </b>
                        <b v-else class="mr-2"> 0 ฿ </b>
                      </th>
                    </tr>
                    <tr>
                      <th
                        class="text-right"
                        colspan="12"
                        style="background: #fff; color: black"
                      >
                        <b>{{ $t("thaiWarehouseFee") }}</b>
                      </th>
                      <th
                        class="text-right"
                        colspan="3"
                        style="background: #fff; color: black"
                      >
                        <b class="mr-2">
                          {{ deliverypay ? Commas(deliverypay) : 0 }} ฿
                        </b>
                      </th>
                    </tr>
                    <tr>
                      <th
                        class="text-right"
                        colspan="12"
                        style="background: #fff; color: black"
                      >
                        <b style="color: red"
                          >{{ $t("estimatedCostNote")
                          }}<span style="color: rgb(0, 0, 0)">
                            {{ $t("domesticDeliveryFee") }}
                          </span></b
                        >
                      </th>
                      <th
                        class="text-right"
                        colspan="3"
                        style="background: #fff; color: black"
                      >
                        <b class="mr-2">
                          {{
                            deliverymoney
                              ? Commas((sumshipping = deliverymoney))
                              : 0
                          }}
                          ฿
                        </b>
                      </th>
                    </tr>
                    <tr>
                      <th
                        class="text-right"
                        colspan="12"
                        style="background: #fff; color: black"
                      >
                        {{ $t("netAmount") }}
                      </th>
                      <th
                        class="text-right"
                        colspan="3"
                        style="background: #fff; color: black"
                      >
                        <b class="mr-2"
                          >{{
                            Commas(sumitem + deliverypay + sumshipping)
                          }}
                          ฿</b
                        >
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div class="d-flex align-items-center justify-content-start p-2">
                <!-- <span class="czx ctx-yellow">
                {{ $t('checkGoods') }}
              </span> -->

                <span class="czx ctx-green">
                  {{ $t("checkOrder") }}
                </span>

                <span class="czx ctx-orange">
                  {{ $t("woodPacking") }}
                </span>

                <span class="czx ctx-red">
                  {{ $t("noPrice") }}
                </span>

                <span class="czx ctx-purple">
                  {{ $t("claim") }}
                </span>
              </div>
            </div>
          </b-col>
          <div class="d-flex align-items-center justify-content-start p-2" />
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormRadio,
  BFormTextarea,
  BTable,
  BFormFile,
  BOverlay,
  // BInputGroup,
  // BInputGroupAppend,
  // BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import vSelect from "vue-select";
import VueImageLightboxCarousel from "vue-image-lightbox-carousel";

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormRadio,
    // ThisHeader,
    BFormFile,
    BTable,
    vSelect,
    BFormTextarea,
    BOverlay,
    VueImageLightboxCarousel,
    // BInputGroup,
    // BInputGroupAppend,
    // BFormDatepicker,
  },
  data() {
    return {
      required,
      email,
      images: [],
      showLightbox: false,
      checkProduct: false,
      customerCode: null,
      parcelCode: null,
      purchaseOrder: null,
      transport: 0,
      keyword: null,
      status: null,
      remark: null,
      amount: 1,
      weight: 0,
      width: 0,
      height: 0,
      long: 0,
      ThinkPerKilo: 0,
      calPriceAuto: true,
      parcelExport: null,
      file: null,
      selectedFiles: [],
      uploadedImages: [],
      imagePreviewUrls: [],
      ID: this.$route.params.id,
      // Arritem: this.$route.params.arritems,
      // Arritem: this.$route.params.arritems,
      items: [],
      getaddress: [],
      options: [
        { value: "all", text: "ทั้งหมด" },
        { value: 1, text: "เติมเงิน" },
        { value: 2, text: "ถอนเงิน" },
        { value: 3, text: "ชำระค่านำเข้า" },
        { value: 4, text: "ค่าสินค้า" },
        { value: 5, text: "ส่วนต่างค่าขนส่งในไทย" },
        { value: 6, text: "ค่าสินค้าใน MALL" },
        { value: 7, text: "ฝากจ่ายเถาเปา" },
        { value: 8, text: "ค่า Commission" },
        { value: 9, text: "เติมเงินอาลีเพย์" },
        { value: 10, text: "โอนเงินไปจีน" },
        { value: 11, text: "แจ้งไม่ได้รับสินค้า" },
        { value: 12, text: "แจ้งร้านค้าคืนเงิน" },
        { value: 13, text: "ค่าจัดส่งในไทย" },
        { value: 14, text: "แลกเงินหยวนเป็นเงินบาท" },
        { value: 15, text: "ส่งของไปจีน" },
        { value: 16, text: "แลกแต้ม" },
        // { value: 17, text: 'อื่นๆ' },
      ],
      Fieldstex: [
        { key: "set", label: "", thStyle: { width: "5%" } },
        { key: "texfullname", label: "นาม", thStyle: { width: "20%" } },
        { key: "address", label: "ที่อยู่", thStyle: { width: "20%" } },
        { key: "telephone", label: "เบอร์โทรศัพท์", thStyle: { width: "15%" } },
        {
          key: "texnumber",
          label: "เลขผู้เสียภาษี",
          thStyle: { width: "15%" },
        },
        { key: "typetex", label: "ประเภทลูกค้า", thStyle: { width: "20%" } },
        { key: "filetex", label: "เอกสาร", thStyle: { width: "20%" } },
        { key: "actions", label: "จัดการ", thStyle: { width: "10%" } },
      ],
      addrFields: [
        { key: "set", label: "", thStyle: { width: "5%" } },
        { key: "fullname", label: "ชื่อ - สกุล", thStyle: { width: "20%" } },
        { key: "tel", label: "เบอร์โทรศัพท์", thStyle: { width: "20%" } },
        { key: "province", label: "จังหวัด", thStyle: { width: "15%" } },
        { key: "postalcode", label: "รหัสไปรษณีย์", thStyle: { width: "10%" } },
        { key: "address", label: "ที่อยู่", thStyle: { width: "20%" } },
        { key: "actions", label: "จัดการ", thStyle: { width: "10%" } },
      ],
      addrFieldshistory: [
        { key: "set", label: "", thStyle: { width: "10%" } },
        {
          key: "fullname",
          label: this.$t("firstName"),
          thStyle: { width: "15%" },
        },
        {
          key: "address",
          label: this.$t("address"),
          thStyle: { width: "15%" },
        },
        { key: "tel", label: this.$t("key-81"), thStyle: { width: "10%" } },
        { key: "sender", label: this.$t("sender"), thStyle: { width: "15%" } },
        {
          key: "deliveryType",
          label: this.$t("deliveryType"),
          thStyle: { width: "20%" },
        },
        {
          key: "province",
          label: this.$t("province"),
          thStyle: { width: "10%" },
        },
        { key: "actions", label: this.$t("manage"), thStyle: { width: "5%" } },
      ],
      postalCode: null,
      fullname: null,
      phoneNum: null,
      address: null,
      Province: null,
      district: null,
      subdistrict: null,
      postal: null,
      setDefault: 0,
      selectedProvince: null,
      selectedDistrict: null,
      selectedSubDistrict: null,
      itemspvc: [],
      idistrict: [],
      iSubdistrict: [],
      izipcode: [],
      subDistrict: [],
      receipt: false,
      delivery: 0,
      itemcoupon: [],
      Fieldscoupon: [
        { key: "setcoupons", label: "", thStyle: { width: "5%" } },
        { key: "coupons", label: "คูปอง", thStyle: { width: "25%" } },
        {
          key: "detailcoupons",
          label: "รายละเอียด",
          thStyle: { width: "70%" },
        },
      ],
      couponitem: null,
      couponcheck: null,

      telnumber: null,
      sumsave: 0,
      sumservice: 0,
      itemsTax: null,
      itemsTaxall: null,
      itemsTaxpayer: [],
      payNow: 0,
      selectfile: null,
      namefile: null,
      idtex: null,
      typetex: 1,
      texfullname: null,
      addresstex: null,
      telephone: null,
      Taxpayer: null,
      paymentuser: [],
      sumuser: 0,
      rolelist: [],
      productType: null,
      transports: 0,
      itempro: [],
      bydelivery: null,
      itemshipping: [],
      sumshipping: 0,
      deliverymoney: 0,
      deliverypay: 0,
      proItems: [],
      sumitem: 0,
      liveryname: null, // ชื่อผู้ส่ง
      deliveryaddress: null, // ที่อยู่ผู้ส่ง
      deliverykeyword: null, // เบอร์ติดต่อผู้ส่ง
      getfullname: null,
      addressget: null,
      general: 0,
      showOver: false,
      isDisabled: false,
      WarehousePrice: null,
      gethistory: [],
      history_id: null,
    };
  },
  computed: {
    Arritem() {
      return JSON.parse(localStorage.getItem("paymentItems"));
    },
    optiondelivery() {
      return [
        { value: 0, label: this.$t("key-310") },
        { value: 1, label: "ANAN CARGO" },
        { value: 2, label: this.$t("key-311") },
        { value: 3, label: this.$t("key-312") },
      ];
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    provinceOptions() {
      return this.itemspvc.map((item) => ({
        province_id: item.province_id,
        province_name: item.province_name,
      }));
    },
    filteredDistricts() {
      if (this.selectedProvince) {
        return this.idistrict
          .filter((ele) => ele.province_id === this.selectedProvince)
          .map((item) => ({
            district_id: item.district_id,
            district_name: item.district_name,
            province_id: item.province_id,
          }));
      }
      return this.idistrict.map((item) => ({
        district_id: item.district_id,
        district_name: item.district_name,
        province_id: item.province_id,
      }));
    },
    filteredSubDistricts() {
      if (this.selectedDistrict) {
        return this.iSubdistrict
          .filter((ele) => ele.ampur_id === this.selectedDistrict)
          .map((item) => ({
            tumbon_id: item.tumbon_id,
            sub_district_name: item.sub_district_name,
            ampur_id: item.ampur_id,
            changwat_id: item.changwat_id,
          }));
      }
      if (this.selectedProvince) {
        return this.iSubdistrict
          .filter((ele) => ele.changwat_id === this.selectedProvince)
          .map((item) => ({
            tumbon_id: item.tumbon_id,
            sub_district_name: item.sub_district_name,
            ampur_id: item.ampur_id,
            changwat_id: item.changwat_id,
          }));
      }
      return this.iSubdistrict.map((item) => ({
        ampur_id: item.ampur_id,
        sub_district_name: item.sub_district_name,
        tumbon_id: item.tumbon_id,
        changwat_id: item.changwat_id,
      }));
    },
    filteredpostalCode() {
      if (this.selectedSubDistrict) {
        return this.izipcode
          .filter((ele) => ele.tum_id === this.selectedSubDistrict)
          .map((item) => ({
            tum_id: item.tum_id,
            zipcode: item.zipcode,
          }));
      }
      return this.izipcode.map((item) => ({
        tum_id: item.tum_id,
        zipcode: item.zipcode,
      }));
    },

    filteredDistrictsnew() {
      if (this.Province) {
        return this.idistrict
          .filter((ele) => ele.province_id === this.Province)
          .map((item) => ({
            district_id: item.district_id,
            district_name: item.district_name,
            province_id: item.province_id,
          }));
      }
      return this.idistrict.map((item) => ({
        district_id: item.district_id,
        district_name: item.district_name,
        province_id: item.province_id,
      }));
    },
    filteredSubDistrictsnew() {
      if (this.district) {
        return this.iSubdistrict
          .filter((ele) => ele.ampur_id === this.district)
          .map((item) => ({
            tumbon_id: item.tumbon_id,
            sub_district_name: item.sub_district_name,
            ampur_id: item.ampur_id,
            changwat_id: item.changwat_id,
          }));
      }
      if (this.Province) {
        return this.iSubdistrict
          .filter((ele) => ele.changwat_id === this.Province)
          .map((item) => ({
            tumbon_id: item.tumbon_id,
            sub_district_name: item.sub_district_name,
            ampur_id: item.ampur_id,
            changwat_id: item.changwat_id,
          }));
      }
      return this.iSubdistrict.map((item) => ({
        ampur_id: item.ampur_id,
        sub_district_name: item.sub_district_name,
        tumbon_id: item.tumbon_id,
        changwat_id: item.changwat_id,
      }));
    },
    provinceOptionsnew() {
      return this.itemspvc.map((item) => ({
        province_id: item.province_id,
        province_name: item.province_name,
      }));
    },

    checktransports() {
      if (this.transport === 3 && this.transports === 0) {
        return [];
      }
      if (this.transports === 4) {
        return this.rolelist.filter((ele) => ele.delivery_source === 1);
      }
      if (this.transports === 5) {
        return this.rolelist.filter((ele) => ele.destination === 1);
      }
      if (this.transport === "3") {
        return this.rolelist;
      }
      return this.rolelist;

      // if (item === 4) {
      //   return this.rolelist.filter(ele => ele.delivery_source === 1)
      //   // console.log(this.rolelist)
      // }

      // console.log(this.rolelist)
    },
  },
  mounted() {
    this.getItem();
    this.getAddress();
    this.getProvince();
    this.getDistrict();
    this.getSubdistrict();
    this.getzipcode();
    this.getproductType();
    this.getTexpay();
    this.getcoupon();
    this.getgeneral();
    this.GetWarehouse();
    this.history();
  },
  methods: {
    async history() {
      try {
        const { data: res } = await this.$http.get(
          "/CustomerPayment/Gethistory"
        );
        if (res.status && res.data.length > 0) {
          this.gethistory = res.data;
          this.$bvModal.show("modal-history");
        } else {
          this.gethistory = [];
        }
      } catch (err) {
        console.log(err);
      }
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(
        /^data:image\/([A-Za-z-+\/]+);base64,(.+)$/
      );
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`;
      }
      return filename;
    },
    showPicture(picture) {
      this.showLightbox = true;
      // console.log(picture)
      // eslint-disable-next-line no-shadow
      // picture.forEach(picture => {
      const img = { path: this.GetImg("filetex", picture) };
      this.images.push(img);
      // })
    },
    closeBox() {
      this.showLightbox = false;
      this.images = [];
    },
    async GetWarehouse() {
      try {
        const { data: res } = await this.$http.get("/warehouse/warehouse_show");
        this.WarehousePrice = res;
      } catch (err) {
        console.log(err);
      }
    },
    async getAddress() {
      try {
        const params = {
          uid: this.ID,
        };
        this.$http
          .get("/Paymentuser/getAddress", { params })
          .then((response) => {
            this.getaddress = response.data;
            this.getaddress.forEach((ele) => {
              if (ele.defaultget === 1) {
                this.getfullname = ele.fullname;
                this.addressget = ele.address;
                this.selectedProvince = ele.province_id;
                this.selectedDistrict = ele.district_id;
                this.selectedSubDistrict = ele.subdistrict_id;
                this.postalCode = ele.postalcode;
                this.telnumber = ele.tel;
              }
            });
            // console.log(this.Arritem)
          });
      } catch (e) {
        console.log(e);
      }
    },
    ActiveData(data) {
      if (data) {
        this.history_id = data._id;
        this.getfullname = data.address.fullname;
        this.addressget = data.address.address;
        this.selectedProvince = data.address.province_id;
        this.selectedDistrict = data.address.district_id;
        this.selectedSubDistrict = data.address.subdistrict_id;
        this.postalCode = data.address.postalcode;
        this.telnumber = data.address.tel;
        this.transport = data.typedelivery.id;
        this.transportsnew(this.transport);
        if (
          data.typedelivery.id === 3 &&
          data.typedelivery.delivery_type === 1
        ) {
          this.transports = 4;
          this.transportsnew(this.transports);
          this.productType = this.checktransports.find(
            (ele) => ele._id == data.typedelivery.delivery_id
          );
          this.inputtransports();
        } else if (
          data.typedelivery.id === 3 &&
          data.typedelivery.delivery_type === 2
        ) {
          this.transports = 5;
          this.transportsnew(this.transports);
          this.productType = this.checktransports.find(
            (ele) => ele._id == data.typedelivery.delivery_id
          );
          this.inputtransports();
        }
        this.delivery = data.delivery.id;
        if (data.delivery.id === 3) {
          this.deliveryname = data.delivery.name;
          this.deliveryaddress = data.delivery.address;
          this.deliverykeyword = data.delivery.tel;
        }
        this.$bvModal.hide("modal-history");
      }
    },
    Deletehistory(data) {
      this.$bvModal
        .msgBoxConfirm("คุณต้องการลบที่อยู่ใช่หรือไม่?", {
          title: "ยืนยันการลบข้อมูล",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((result) => {
          if (result) {
            const params = {
              // eslint-disable-next-line no-underscore-dangle
              ID: data._id,
            };
            this.$http
              .post("/CustomerPayment/delete/history", params)
              .then((response) => {
                // this.getTexpay()
                if (response.data.status) {
                  this.history();
                  this.getItem();
                  this.$bvToast.toast("ลบข้อมูลสำเร็จ", {
                    title: "สำเร็จ",
                    variant: "success",
                    solid: true,
                    autoHideDelay: 1500,
                  });
                }
              });
          }
        })
        .catch((err) => {
          this.SwalError(err.response.data.data);
          console.log(err);
        });
    },
    checkcharge_per(item) {
      if (item.charge_per_save === item.charge_per_kilo) {
        return "คิดตามกิโล";
      }
      if (item.charge_per_save === item.charge_per_cue) {
        return "คิดตามขนาด";
      }
      return "";
    },
    addNewAddress() {
      this.$refs.addNewAddress.validate().then((success) => {
        if (success) {
          const formData = {
            uid: this.ID,
            username: this.Arritem[0].username,
            id: this.idaddress,
            fullname: this.fullname,
            tel: this.phoneNum,
            address: this.address,
            subdistrict_id: this.subdistrict,
            subdistrict: "",
            district_id: this.district,
            district: "",
            province_id: this.Province,
            province: "",
            postalcode: this.postal,
            defaultget: this.setDefault,
          };
          if (this.Province) {
            const provi = this.itemspvc.find(
              (ele) => ele.province_id === this.Province
            );
            formData.province = provi.province_name;
          }
          if (this.district) {
            const arr = this.idistrict.find(
              (ele) => ele.district_id === this.district
            );
            formData.district = arr.district_name;
          }
          if (this.subdistrict) {
            const arr = this.iSubdistrict.find(
              (ele) => ele.tumbon_id === this.subdistrict
            );
            formData.subdistrict = arr.sub_district_name;
          }
          // console.log(formData)
          this.$http
            .post("/Paymentuser/updateAddress", formData)
            .then(() => {
              // this.$bvModal.hide('modal-receive-add')
              this.getAddress();
              this.Success("อัพเดทที่อยู่เรียบร้อย");
            })
            .catch((error) => {
              this.SwalError(error.response.data.message);
            });
          // this.$bvModal.hide('modal-receive-add')
        }
      });
    },
    // CommasCue(x) {
    //   if (!x) {
    //     return '0'
    //   }
    //   const parts = x.toString().split('.')
    //   parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    //   if (parts[1]) {
    //     parts[1] = parseFloat(`0.${parts[1]}`).toFixed(4).split('.')[1]
    //   } else {
    //     parts.push('0000')
    //   }
    //   return parts.join('.')
    // },
    Submit() {
      this.showOver = true;
      this.isDisabled = true;
      if (Number(this.payNow) !== 1) {
        this.Alert(false, "กรุณายอมรับข้อตกลงก่อนดำเนินการต่อไป");
        this.showOver = false;
        this.isDisabled = false;
      } else if (this.sumpay > this.sumuser) {
        this.Alert(false, "ยอดเงินในระบบไม่พอ");
        this.isDisabled = false;
        this.showOver = false;
      } else if (this.transport === 0) {
        this.Alert(false, "เลือกวิธีจัดส่ง");
        this.isDisabled = false;
        this.showOver = false;
      } else if (this.delivery === 0) {
        this.Alert(false, "เลือกผู้ส่ง");
        this.isDisabled = false;
        this.showOver = false;
      } else {
        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
            let itemdelivery = [];
            if (this.delivery === 1) {
              const delivery = {
                id: 1,
                name: "ANAN CARGO",
              };
              itemdelivery = delivery;
            }
            if (this.delivery === 2) {
              const delivery = {
                id: 2,
                name: "ไม่แสดงราคา",
              };
              itemdelivery = delivery;
            }
            if (this.delivery === 3) {
              const delivery = {
                id: 3,
                name: this.deliveryname,
                address: this.deliveryaddress,
                tel: this.deliverykeyword,
              };
              itemdelivery = delivery;
            }
            let itemtype = [];
            let typeout = null;
            let itemstatus = null;
            if (this.transport === 1) {
              const arr = {
                id: 1,
                pro_name: "รับสินค้าเอง",
                paydelivery: "จ่ายค่าขนส่งต้นทาง",
              };
              itemtype = arr;
              typeout = 1;
            }
            if (this.transport === 2) {
              const arr = {
                id: 2,
                pro_name: "บริษัทจัดส่ง 690 บาท",
                paydelivery: "จ่ายค่าขนส่งต้นทาง",
              };
              itemtype = arr;
              itemstatus = 1;
            }
            if (this.transport === 3) {
              if (this.transports === 4) {
                const arr = {
                  id: 3,
                  pro_name: `${this.productType.name}`,
                  paydelivery: "จ่ายค่าขนส่งต้นทาง",
                  delivery_id: this.productType._id,
                  delivery_type: 1,
                };
                itemtype = arr;
                itemstatus = 1;
              }
              if (this.transports === 5) {
                const arr = {
                  id: 3,
                  pro_name: `${this.productType.name}`,
                  paydelivery: "จ่ายค่าขนส่งปลายทาง",
                  delivery_id: this.productType._id,
                  delivery_type: 2,
                };
                itemtype = arr;
                itemstatus = 1;
              }
            }
            let arritem = null;
            if (this.receipt && this.itemsTax) {
              arritem = this.itemsTax;
              // this.itemsTax.forEach(ele => {
              //   arritem = ele
              //   // console.log(ele)
              // })
              // console.log(this.itemsTax)
            }
            const obj = {
              uid: this.ID,
              payNow: 1,
              postnumberlot: this.Arritem,
              address: {
                fullname: this.getfullname,
                tel: this.telnumber,
                address: this.addressget,
                province_id: this.selectedProvince,
                province_name: "",
                district_id: this.selectedDistrict,
                district: "",
                subdistrict_id: this.selectedSubDistrict,
                subdistrict: "",
                postalcode: this.postalCode,
              },
              typedelivery: itemtype,
              delivery: itemdelivery,
              payment: this.sumpay,
              remark: this.remark,
              itemnumber: 1,
              username: this.Arritem[0].username,
              itemtex: arritem,
              order_out: typeout,
              order_thaiout: itemstatus,
              Service_fee: this.deliverypay,
              Shipping_cost: this.sumshipping,
              receipt: this.receipt,
              promotion: null,
              coupon: null,
              user_member: this.Arritem[0].user_member,
              user_member_check: this.Arritem[0].user_member_check,
              user_member_id: this.Arritem[0].user_member_id,
              history_id: this.history_id,
            };
            if (this.couponitem) {
              const arr = {
                // eslint-disable-next-line no-underscore-dangle
                id_coupon: this.couponitem._id,
                discount_coupon: this.couponitem.discount,
                coupon_name: this.couponitem.name,
              };
              obj.coupon = arr;
            }
            if (this.selectedProvince) {
              const provi = this.itemspvc.find(
                (ele) => ele.province_id === this.selectedProvince
              );
              obj.address.province_name = provi.province_name;
            }
            if (this.selectedDistrict) {
              const arr = this.idistrict.find(
                (ele) => ele.district_id === this.selectedDistrict
              );
              obj.address.district = arr.district_name;
            }
            if (this.selectedSubDistrict) {
              const arr = this.iSubdistrict.find(
                (ele) => ele.tumbon_id === this.selectedSubDistrict
              );
              obj.address.subdistrict = arr.sub_district_name;
            }
            this.$http
              .post("/Paymentuser/store", obj)
              .then((response) => {
                if (response.data.status) {
                  this.Success("บันทึกการชำระค่านำเข้าเรียบร้อย");
                  this.$router.push({ name: "export" });
                  this.showOver = false;
                }
              })
              .catch((error) => {
                this.SwalError(error.response.data);
                this.showOver = false;
                this.isDisabled = false;
              });

            // this.$http.post('/Tracking/UpdatePostnumber', obj)
            //   .then(() => {
            //     this.Success('บันทึกข้อมูลพัสดุเรียบร้อย')

            //     this.$router.push({ name: 'tracking' })
            //   })
            //   .catch(error => {
            //     this.getItem()
            //     this.SwalError(error.response.data.message)
            //   })
          } else {
            this.isDisabled = false;
            this.showOver = false;
          }
        });
      }
    },
    async getTexpay() {
      try {
        const params = {
          uid: this.ID,
        };
        const { data: res } = await this.$http.get("/Paymentuser/getTaxpayer", {
          params,
        });
        // console.log(res)
        this.itemsTaxpayer = res;
      } catch (e) {
        console.log(e);
      }
    },
    additemtex() {
      // const Check = this.itemsTaxpayer.filter(ele => ele.isSelected)
      this.itemsTax = this.itemsTaxall;
    },
    async getcoupon() {
      try {
        const params = {
          id: this.ID,
        };
        const { data: res } = await this.$http.get("/Paymentuser/getCoupon", {
          params,
        });
        this.itemcoupon = res;
        console.log(this.itemcoupon);
      } catch (e) {
        console.log(e);
      }
    },
    async getItem() {
      try {
        const params = {
          ID: this.ID,
        };
        const { data: res } = await this.$http.get(
          "/Paymentuser/getBalanceOne",
          { params }
        );
        this.paymentuser = res;
        // console.log(this.paymentuser)
        // .then(response => {
        //   this.paymentuser = response.data
        //   // console.log(response.data)
        // })
        // console.log(obj)
      } catch (e) {
        console.log(e);
      }
      // console.log(this.ID)
      // const Arritems = this.$route.params.arritems
      // console.log(this.Arritems)
    },
    submitcoupon() {
      this.$refs.coupon.validate().then((success) => {
        if (success) {
          this.couponitem = this.couponcheck;
        }
      });

      this.$bvModal.hide("modal-coupon");
    },
    async getgeneral() {
      try {
        const { data: res } = await this.$http.get("/general/show?agent_id=1");
        this.general = res.data.service_fee_change_sender;
      } catch (e) {
        console.log(e);
      }
    },
    inputtransports() {
      this.bydelivery = `${this.productType.name}`;
      if (this.productType.delivery_type === 1) {
        if (this.productType.destination === 1 && this.transports === 5) {
          this.deliverymoney = 0;
        } else if (
          this.productType.delivery_source === 1 &&
          this.transports === 4
        ) {
          const weight = this.Arritem.reduce(
            (total, ele) => total + ele.weight * ele.product_amount,
            0
          );
          let maxPrice = 0;
          if (this.productType.rate.length) {
            this.productType.rate.forEach((ele) => {
              if (ele.min <= weight && ele.price > maxPrice) {
                maxPrice = ele.price;
              }
            });
            this.deliverymoney = maxPrice;
          } else {
            this.deliverymoney = 0;
          }
        } else {
          this.deliverymoney = 0;
        }
      }
      if (this.productType.delivery_type === 2) {
        if (this.productType.destination === 1 && this.transports === 5) {
          this.deliverymoney = 0;
        } else if (
          this.productType.delivery_source === 1 &&
          this.transports === 4
        ) {
          const charge_cue = this.Arritem.reduce(
            (total, ele) => total + ele.charge_cue * ele.product_amount,
            0
          );
          let maxPrice = 0;
          if (this.productType.rate.length) {
            this.productType.rate.forEach((ele) => {
              if (ele.min <= charge_cue && ele.price > maxPrice) {
                maxPrice = ele.price;
              }
            });
            this.deliverymoney = maxPrice;
          } else {
            this.deliverymoney = 0;
          }
        } else {
          this.deliverymoney = 0;
        }
      }
      if (this.WarehousePrice) {
        const checkID = this.WarehousePrice.free_shipping.find(
          (ele) => ele._id == this.productType._id
        );
        if (checkID) {
          this.deliverypay = 0;
        } else {
          const weight = this.Arritem.reduce(
            (total, ele) => total + ele.weight * ele.product_amount,
            0
          );
          let maxPrice = 0;
          this.WarehousePrice.rate.forEach((ele) => {
            if (ele.weight <= weight && ele.price > maxPrice) {
              maxPrice = ele.price;
            }
          });
          this.deliverypay = maxPrice;
        }
      }
      // this.productType.rate.forEach(ele => {
      //   if (ele.min < this.Arritem.length) {
      //     this.deliverymoney = ele.price
      //   }
      // })
    },
    Alert(status, title, mes) {
      this.$swal({
        icon: `${status ? "warning" : "error"}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: "btn btn-warning",
        },
      });
    },
    checkdelivery(item) {
      if (item === 0 || item === 1 || item === 2) {
        if (this.WarehousePrice && this.productType) {
          const checkID = this.WarehousePrice.free_shipping.find(
            (ele) => ele._id == this.productType._id
          );
          if (checkID) {
            this.deliverypay = 0;
          } else {
            const weight = this.Arritem.reduce(
              (total, ele) => total + ele.weight * ele.product_amount,
              0
            );
            let maxPrice = 0;
            this.WarehousePrice.rate.forEach((ele) => {
              if (ele.weight <= weight && ele.price > maxPrice) {
                maxPrice = ele.price;
              }
            });
            this.deliverypay = maxPrice;
          }
        } else {
          this.deliverypay = 0;
        }
      } else if (item === 3) {
        if (this.WarehousePrice && this.productType) {
          const checkID = this.WarehousePrice.free_shipping.find(
            (ele) => ele._id == this.productType._id
          );
          if (checkID) {
            this.deliverypay = 0;
          } else {
            const weight = this.Arritem.reduce(
              (total, ele) => total + ele.weight * ele.product_amount,
              0
            );
            let maxPrice = 0;
            this.WarehousePrice.rate.forEach((ele) => {
              if (ele.weight <= weight && ele.price > maxPrice) {
                maxPrice = ele.price;
              }
            });
            this.deliverypay = maxPrice;
          }
        } else {
          this.deliverypay = 0;
        }
        this.deliverypay += this.general;
      } else if (this.WarehousePrice && this.productType) {
        const checkID = this.WarehousePrice.free_shipping.find(
          (ele) => ele._id == this.productType._id
        );
        if (checkID) {
          this.deliverypay = 0;
        } else {
          const weight = this.Arritem.reduce(
            (total, ele) => total + ele.weight * ele.product_amount,
            0
          );
          let maxPrice = 0;
          this.WarehousePrice.rate.forEach((ele) => {
            if (ele.weight <= weight && ele.price > maxPrice) {
              maxPrice = ele.price;
            }
          });
          this.deliverypay = maxPrice;
        }
      } else {
        this.deliverypay = 0;
      }
    },
    addAddress() {
      this.getaddress.forEach((ele) => {
        if (ele.isSelected === true) {
          this.getfullname = ele.fullname;
          this.addressget = ele.address;
          this.selectedProvince = ele.province_id;
          this.selectedDistrict = ele.district_id;
          this.selectedSubDistrict = ele.subdistrict_id;
          this.postalCode = ele.postalcode;
          this.telnumber = ele.tel;
        }
      });

      // console.log(obj)
    },
    transportsnew(item) {
      this.productType = null;
      this.bydelivery = null;
      this.itempro = [];
      this.itemshipping = [];
      this.sumshipping = 0;
      this.deliverymoney = 0;
      // console.log(this.itempro)
      if (item === 1) {
        this.bydelivery = "รับสินค้าเอง";
        this.deliverymoney = 0;
        this.deliverypay = 0;
        this.sumshipping = 0;
        this.delivery = 0;
        this.transports = 0;
      } else if (item === 2) {
        this.bydelivery = "บริษัทจัดส่ง 690 บาท";
        this.deliverymoney = 690;
        this.deliverypay = 0;
        this.delivery = 0;
        this.sumshipping = 0;
        this.transports = 0;
      } else if (item === 3) {
        this.bydelivery = "-";
        this.deliverymoney = 0;
        this.deliverypay = 0;
        this.delivery = 0;
        this.sumshipping = 0;
        this.transports = 0;
      } else {
        this.deliverypay = 0;
        this.sumshipping = 0;
        this.deliverymoney = 0;
      }
      if (item === 4 || item === 5) {
        // const arritem = this.deliverypay
        this.deliverypay = 0;
        this.delivery = 0;
        this.sumshipping = 0;
      }
      // console.log(item)
      // if (item === 4) {
      //   console.log(this.productType)
      // }
    },
    EditType(item) {
      if (item) {
        // console.log(item)
        this.uidaddress = item.uid;
        this.idaddress = item.id;
        this.fullname = item.fullname;
        this.phoneNum = item.tel;
        this.address = item.address;
        this.Province = item.province_id;
        this.district = item.district_id;
        this.subdistrict = item.subdistrict_id;
        this.postal = item.postalcode;
        this.setDefault = item.defaultget;
      } else {
        this.idaddress = null;
        this.uidaddress = null;
        this.fullname = null;
        this.phoneNum = null;
        this.address = null;
        this.Province = null;
        this.district = null;
        this.subdistrict = null;
        this.postal = null;
        this.setDefault = null;
      }
      this.$bvModal.show("modal-receive-add");
    },
    filetex(event) {
      this.selectfile = event.target.files;
      this.namefile = null;
      const reader = new FileReader();
      const filepath = this.selectfile[0];
      reader.onload = (e) => {
        // console.log(e.target.result)
        this.namefile = e.target.result;
        // console.log(this.namemap)
      };
      reader.readAsDataURL(filepath);
    },
    addNewTex() {
      this.$refs.addNewtexse.validate().then((success) => {
        if (success) {
          const obj = {
            id: this.idtex,
            uid: this.ID,
            typetex: this.typetex,
            texfullname: this.texfullname,
            address: this.addresstex,
            telephone: this.telephone,
            texnumber: this.Taxpayer,
            filetex: this.namefile,
          };
          this.$http.post("/Paymentuser/storeupdatetex", obj).then(() => {
            this.getTexpay();
            this.getItem();
            this.Success("เพิ่มข้อมูลผู้เสียภาษีเรียบร้อย");
            this.$bvModal.hide('modal-receive-add-amend')
          });
        }
      });
    },
    Deletetex(data) {
      this.$bvModal
        .msgBoxConfirm("คุณต้องการลบที่อยู่ใช่หรือไม่?", {
          title: "ยืนยันการลบข้อมูล",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((result) => {
          if (result) {
            const params = {
              // eslint-disable-next-line no-underscore-dangle
              id: data._id,
            };
            this.$http.post("/Paymentuser/deletetex", params).then(() => {
              this.getTexpay();
              this.getItem();
              this.$bvToast.toast("ลบข้อมูลสำเร็จ", {
                title: "สำเร็จ",
                variant: "success",
                solid: true,
                autoHideDelay: 1500,
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    Deletecoupon() {
      this.couponitem = null;
      this.couponcheck = null;
    },
    async getproductType() {
      try {
        const { data: res } = await this.$http.get(
          "/Paymentuser/getShippingType"
        );
        this.rolelist = res;
        // .then(response => {
        //   this.rolelist = response.data
        //   // console.log(response.data)
        // })
      } catch (e) {
        console.log(e);
      }
    },
    async getProvince() {
      try {
        const { data: res } = await this.$http.get("/Addresscustomer/province"); // จังหวัด
        this.itemspvc = res;
        // console.log(this.itemspvc)
      } catch (e) {
        console.log(e);
      }
    },
    async getDistrict() {
      try {
        const { data: res } = await this.$http.get("/Addresscustomer/district"); // อำเภอ
        this.idistrict = res;
      } catch (e) {
        console.log(e);
      }
    },
    async getSubdistrict() {
      try {
        const { data: res } = await this.$http.get(
          "/Addresscustomer/Subdistrict"
        ); // ตำบล
        this.iSubdistrict = res;
      } catch (e) {
        console.log(e);
      }
    },
    async getzipcode() {
      try {
        const { data: res } = await this.$http.get("/Addresscustomer/zipcode"); // ตำบล
        this.izipcode = res;
      } catch (e) {
        console.log(e);
      }
    },
    updateDistricts() {
      this.selectedDistrict = null;
      this.selectedSubDistrict = null; // อำเภอ
      this.postalCode = null;
    },
    updateSubDistricts() {
      this.selectedSubDistrict = null;
      this.postalCode = null; // ตำบล
    },
    updatePostalcode() {
      this.postalCode = null;
      const arr = this.izipcode.find(
        (ele) => ele.tum_id === this.selectedSubDistrict
      );
      this.postalCode = arr.zipcode;
    },
    updateDistrictsnew() {
      this.district = null;
      this.subdistrict = null; // อำเภอ
      this.postal = null;
    },
    updateSubDistrictsnew() {
      this.subdistrict = null;
      this.postal = null; // ตำบล
    },
    updatePostalcodenew() {
      this.postal = null;
      const arr = this.izipcode.find((ele) => ele.tum_id === this.subdistrict);
      this.postal = arr.zipcode;
    },
    checkreceipt(item) {
      if (item) {
        this.itemsTax = null;
        this.itemsTaxall = null;
        // if (this.itemsTaxpayer) {
        //   // eslint-disable-next-line array-callback-return
        //   this.itemsTaxpayer.find(ele => {
        //     if (ele.isSelected) {
        //       // eslint-disable-next-line no-param-reassign
        //       ele.isSelected = false
        //     }
        //   })
        // }

        // console.log(this.itemsTaxpayer)
      }
    },
    checktypetex(item) {
      if (item === 1) {
        return "บุคคลธรรมดา";
      }
      if (item === 2) {
        return "นิติบุคคล";
      }
      return "";
    },
    EditTex(item) {
      if (item) {
        // eslint-disable-next-line no-underscore-dangle
        this.idtex = item._id;
        this.typetex = item.typetex;
        this.texfullname = item.texfullname;
        this.addresstex = item.address;
        this.telephone = item.telephone;
        this.Taxpayer = item.texnumber;
        this.namefile = item.filetex;
      } else {
        this.typetex = 1;
        this.texfullname = null;
        this.addresstex = null;
        this.telephone = null;
        this.Taxpayer = null;
        this.namefile = null;
        this.idtex = null;
      }
      this.$bvModal.show("modal-receive-add-amend");
    },
    Deleteaddress(item) {
      this.$bvModal
        .msgBoxConfirm("คุณต้องการลบที่อยู่ใช่หรือไม่?", {
          title: "ยืนยันการลบข้อมูล",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((result) => {
          if (result) {
            const params = {
              id: item.id,
            };
            this.$http.post("/Paymentuser/deleteaddress", params).then(() => {
              this.getAddress();
              this.$bvToast.toast("ลบข้อมูลสำเร็จ", {
                title: "สำเร็จ",
                variant: "success",
                solid: true,
                autoHideDelay: 1500,
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleFileChanges(event) {
      const { files } = event.target;
      this.imagePreviewUrls = [];
      console.log(this.imagePreviewUrls);
      //   add to local storage
      localStorage.setItem(
        "imagePreviewUrls",
        JSON.stringify(this.imagePreviewUrls[0])
      );

      for (let i = 0; i < files.length; i += 1) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreviewUrls.push(e.target.result);
        };
        reader.readAsDataURL(files[i]);
      }
    },

    addMoreImages() {
      this.$refs.fileInput.click();
      console.log(this.imagePreviewUrls);
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.imgBackground = file;
      this.previewImage(file);
    },
    previewImage(file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreviewUrl = reader.result;
      };
      reader.readAsDataURL(file);
    },
    Success(mes) {
      this.$swal({
        icon: "success",
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    SwalError(mes) {
      this.$swal({
        icon: "error",
        title: `<h3 style="color: #141414">${this.$t("key-298")}</h3>`,
        text: mes,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    showAdd() {
      this.$bvModal.show("add-addr");
    },
    showModal() {
      this.name = null;
      this.phone = null;
      this.address = null;
      this.province = null;
      this.district = null;
      this.subdistrict = null;
      this.postalcode = null;
      this.$bvModal.show("modal-1");
    },
  },
};
</script>

<style>
.btn-select {
  margin-top: 5px;
  padding: 1.5rem 2.5rem;
  border: 1px dashed #979797;
  border-radius: 14px;
  background-color: #c1ecfd;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
